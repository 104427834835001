import styled from "styled-components";

export const Item = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: -0.011em;

  color: ${(props) => props.theme.colors.neutrals.primary.white};
  //color: ${(props) => props.theme.colors.green[300].accent};

  &.active {
    border-bottom: 1px solid ${(props) => props.theme.colors.green[300].accent};
  }
`;

export const Breadcrumb = styled.div`
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  display: flex;
  width: 100%;
  gap: 16px;
`;

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  padding-bottom: 8px;
  margin-right: 8px;
  color: ${(props) => props.theme.colors.neutrals.primary.white};
`;

export const TitleWrapper = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  width: 100%;
  gap: 8px;
  @media ${(props) => props.theme.device.sm} {
    flex-direction: row;
    align-items: center;
  }
`;

export const ActionsWrapper = styled.div`
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  display: flex;
  gap: 8px;
`;

export const Wrapper = styled.div`
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  display: flex;
  gap: 8px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap: 8px;
  margin-bottom: 8px;
`;
